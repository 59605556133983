// @flow
import React from 'react';

import intersectionWith from 'lodash/intersectionWith';
import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import forOwn from 'lodash/forOwn';
import find from 'lodash/find';

import parse from 'html-react-parser';

import {
  Table,
//  ListGroup,
} from 'react-bootstrap';

import type { CloudWordType, specificItemWordPart } from '../PropsInterface';

// We get an array like:
// [{label: 'A', value:'aa'}, {label: 'B', value:'bb'}]
// We want to return a table like:
// <Table><tr><th>A</th><td>aa</td></tr>...</Table>
export function getWordSpecificAsTable(specific: Array<*>) {
  // Some values can be null, collect all the values removing null ones.
  // const values = compact(specific.map((x) => x.value));
  // For the moment if some values are missing, we do not print anthing.
  // if (values.length !== specific.length) return null;
  return (
    <Table striped bordered hover size="sm">
      <tbody>
        {specific.map((d) => {
          if (d.value) {
            return <tr key={d.label}><th>{d.label}</th><td>{d.value}</td></tr>;
          }
          return null;
        })}
      </tbody>
    </Table>
  );
}

function getObjectFromArray(arrayList: Array<*>, objectId: string) {
  // We have an array like: [{id:a,...},{id:b,...},{id:c...},...]
  // And we have objectId 'a'
  const res = intersectionWith(arrayList, [objectId], (o, id) => o.id === id);
  if (res.length === 1) {
    return res[0];
  }
  return {};
}

function msgEnOrEtt(specific: Array<*>) {
  const isEn = getObjectFromArray(specific, 'sv_noun_is_en');
  const isEtt = getObjectFromArray(specific, 'sv_noun_is_ett');
  const isEttn = getObjectFromArray(specific, 'sv_noun_ettn');

  if (isEn.value && isEtt.value) {
    return (<span>Word is <b>EN</b> and <b>ETT</b> ({isEttn.value})</span>);
  }
  if (isEn.value && !isEtt.value) {
    return (<span>Word is <b>EN</b></span>);
  }
  if (!isEn.value && isEtt.value) {
    return (<span>Word is <b>ETT</b></span>);
  }
  return null;
}

function arrayKeysToList(arrayKeys: Array<string>, specific: Array<specificItemWordPart>) {
  // Extract array values from array keys, return value only if exist
  const values = arrayKeys.map((x) => {
    const obj = getObjectFromArray(specific, x);
    if (obj.value) return obj;
    return null;
  });
  // Remove empty objects (just)
  const valuesClean = reject(values, isEmpty);
  // Create list
  const valuesList = valuesClean.map((x) => <li key={x.id}><b>{x.label}:</b>{' '}{x.value}</li>);
  return valuesList;
}

function arrayKeysToArray(arrayKeys: Array<string>, specific: Array<specificItemWordPart>) {
  // Extract array values from array keys, return value only if exist
  const values = arrayKeys.map((x) => {
    const obj = getObjectFromArray(specific, x);
    if (obj.value) return obj;
    return null;
  });
  // Remove empty objects (just)
  const valuesClean = reject(values, isEmpty);
  return valuesClean;
}

function getValueById(items:Array<*>, key: string) {
  const res = find(items, { id: key });
  if (!res) return null;
  return res.value;
}

export function getWordSpecificAsCustomTable(specific: Array<specificItemWordPart>) {
  const keysVerb = ['sv_verb_imperativ', 'sv_verb_infinitiv', 'sv_verb_supinum', 'sv_verb_present', 'sv_verb_present_part', 'sv_verb_preteritum'];

  const valuesVerb = arrayKeysToArray(keysVerb, specific);

  const result = [];
  if (!isEmpty(valuesVerb)) {
    result.push(
      <Table key="word-verbs-table" className="word-verbs-table" striped bordered>
        <tbody>
          <tr>
            <td>
              att {getValueById(valuesVerb, 'sv_verb_infinitiv')}
              <div className="tense">[infinitiv]</div>
            </td>
            <td>
              {getValueById(valuesVerb, 'sv_verb_imperativ')}!
              <div className="tense">[imperativ]</div>
            </td>
            <td>
              {getValueById(valuesVerb, 'sv_verb_supinum')}
              <div className="tense">[supinum]</div>
            </td>
          </tr>
          <tr>
            <td>
              {getValueById(valuesVerb, 'sv_verb_preteritum')}
              <div className="tense">[past]</div>
            </td>
            <td>
              {getValueById(valuesVerb, 'sv_verb_present')}
              <div className="tense">[present]</div>
            </td>
            <td>
              ska {getValueById(valuesVerb, 'sv_verb_infinitiv')}
              <div className="tense">[future]</div>
            </td>
          </tr>
          <tr>
            <td>
              hade {getValueById(valuesVerb, 'sv_verb_supinum')}
              <div className="tense">[past pluperf]</div>
            </td>
            <td>
              har {getValueById(valuesVerb, 'sv_verb_supinum')}
              <div className="tense">[present perf]</div>
            </td>
            <td>
              ska ha {getValueById(valuesVerb, 'sv_verb_supinum')}
              <div className="tense">[future perf]</div>
            </td>
          </tr>
        </tbody>
      </Table>,
    );
  }

  return result;
}

export function getWordSpecificAsList(specific: Array<specificItemWordPart>) {
  const keysNoun = ['sv_noun_sing_indef', 'sv_noun_sing_defin', 'sv_noun_plu_indef', 'sv_noun_plu_defin'];
  const keysVerb = ['sv_verb_imperativ', 'sv_verb_infinitiv', 'sv_verb_supinum', 'sv_verb_present', 'sv_verb_present_part', 'sv_verb_preteritum'];
  const keysAdj = ['sv_adj_indef_sing_en', 'sv_adj_indef_sing_ett', 'sv_adj_defin_sing', 'sv_adj_plural', 'sv_adj_comparativ', 'sv_adj_superlativ_best', 'sv_adj_superlativ_obest'];

  const valuesNoun = arrayKeysToList(keysNoun, specific);
  const valuesVerb = arrayKeysToList(keysVerb, specific);
  const valuesAdj = arrayKeysToList(keysAdj, specific);

  const result = [];

  if (!isEmpty(valuesNoun)) {
    result.push(
      <div key="nounContainer">
        <h3>Noun</h3>
        <ul>
          <li>{msgEnOrEtt(specific)}</li>
          {valuesNoun}
        </ul>
      </div>,
    );
  }

  if (!isEmpty(valuesVerb)) {
    result.push(
      <div key="verbContainer">
        <h3>Verb</h3>
        <ul>
          {valuesVerb}
        </ul>
      </div>,
    );
  }

  if (!isEmpty(valuesAdj)) {
    result.push(
      <div key="adjContainer">
        <h3>Adj</h3>
        <ul>
          {valuesAdj}
        </ul>
      </div>,
    );
  }
  return result;
}

export function renderCloudAsList(words: Array<CloudWordType>) {
  const getVal = (item) => {
    switch (item.value) {
      case 32:
        return <b><u>{item.text}</u></b>;
      case 24:
        return <b>{item.text}</b>;
      case 16:
        return item.text;
      default:
        return null;
    }
  };
  const listItems = words.map((item) => <li key={item.text}>{getVal(item)}</li>);
  return (<ul className="card-body-list">{listItems}</ul>);
}

// Render plain as cloud
export function renderPlainAsCloud(values: Array<string>) {
  const groupedValues = groupBy(values, (x) => x);
  // Grouped values like: { a: [ 'a', 'a' ], b: [ 'b' ] }
  const perSize = [];
  forOwn(groupedValues, (value) => {
    if (value.length === 1) perSize.push({ text: value[0], value: 16 });
    if (value.length === 2) perSize.push({ text: value[0], value: 24 });
    if (value.length >= 3) perSize.push({ text: value[0], value: 32 });
  });
  return perSize;
}

export function renderSpecificAsCloud(values: Array<specificItemWordPart>):Array<CloudWordType> {
  // TODO: remove ~et = (Noun endings short)?
  const result = values.map((x):CloudWordType => ({ text: `${x.value} (${x.label})`, value: 16 }));
  return result;
}

// RENDER plain/cloud as a single line
// Make sure that renderPlainAsSingleLine and renderCloudAsSingleLine return same things!
const renderSingleItem = (text:string, value:number, separator:string): Object => {
  switch (value) {
    case 32:
      return <span className="sing-item" key={text}><b><u>{parse(text)}</u>{separator}</b></span>;
    case 24:
      return <span className="sing-item" key={text}><b>{parse(text)}{separator}</b></span>;
    case 16:
      return <span className="sing-item" key={text}>{parse(text)}{separator}</span>;
    default:
      return null;
  }
};

export function renderSingleCloud(item: CloudWordType): Object {
  return renderSingleItem(item.text, item.value, ' ');
}

export function renderPlainAsSingleLine(words: Array<string>): Array<*> {
  const totalWords = words.length;
  const listItems = words.map((item, index) => {
    const separator = totalWords === index + 1 ? '' : ' - ';
    return renderSingleItem(item, 16, separator);
  });
  return listItems;
}

export function renderCloudAsSingleLine(words: Array<CloudWordType>): Array<*> {
  // Iterate the words and make bold the ones with 32 as value
  if (!words) return [];
  const totalWords = words.length;
  const listItems = words.map((item, index) => {
    // We separate two words appending "-", but for the last one we do not append it
    const separator = totalWords === index + 1 ? '' : ' - ';
    return renderSingleItem(item.text, item.value, separator);
  });
  return listItems;
}
