// @flow
import React, { Component } from 'react';

import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';

interface State {}

interface Props {
  itemIndex: number,
  prevItem: any,
  randomizeItem: any,
  nextPage: any,
  nextItem: any,
}

class WordButtons extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      itemIndex,
      prevItem,
      randomizeItem,
      nextPage,
      nextItem,
    } = this.props;

    // nextPage is optional. If we render a list, there is pagination, there is no "next page", but
    // if we render all words -> verbs, for example, then we need pagination.
    const columnWidht = nextPage ? 3 : 4;

    return (
      <Row className="word-buttons">
        <Col xs={columnWidht}>
          <Button key="1" variant="primary" size="lg" disabled={itemIndex === 0} onClick={() => prevItem()}>
            Prev.
          </Button>
        </Col>
        <Col xs={columnWidht}>
          <Button key="1" variant="primary" size="lg" onClick={() => randomizeItem()}>
            Rand.
          </Button>
        </Col>
        { nextPage ? (
          <Col xs={columnWidht}>
            <Button key="1" variant="primary" size="lg" onClick={() => nextPage()}>
              NextP
            </Button>
          </Col>
        ) : null }
        <Col xs={columnWidht}>
          <Button key="1" variant="primary" size="lg" onClick={() => nextItem()}>
            Next
          </Button>
        </Col>
      </Row>
    );
  }
}

export default WordButtons;
