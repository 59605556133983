// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { withCookies } from 'react-cookie';

import { AllSwedishWordsQuery } from '../../Lib/Queries';

import CommonList from './CommonList';

interface Props {
  data: Object
}

// Send variables objected generated in withRouter, at the end of the class.
function getClassFromVars(variables: Object) {
  if (variables.isVerb) return 'WordClass';
  return 'WordClass';
}

const SwedishWords = (props: Props) => {
  const { data } = props;
  const classGen = getClassFromVars(data.variables);
  return (
    <CommonList
      data={data}
      queryName="allSwedishWords"
      queryList="clauses"
      classGenerator={classGen}
    />
  );
};

export default withCookies(withRouter(graphql(AllSwedishWordsQuery, {
  options: (props) => {
    // We get "type" for internal use, to check the page to render
    // We use isNoun, isAdverb... to build the SQL query.
    // If we have slug="patata", then all isXXX are going to be null,
    // so we will fetch all the items, but in innerContent
    // we will check that the slug is not valid.
    const { cookies } = props;
    const itemsPerPage = cookies.get('settingsItemsPerPage') || 4;
    const variables = {
      first: itemsPerPage,
      type: props.match.params.slug,
      isNoun: (props.match.params.slug === 'nouns') ? 1 : null,
      isAdverb: (props.match.params.slug === 'adverbs') ? 1 : null,
      isVerb: (props.match.params.slug === 'verbs') ? 1 : null,
      isAdjective: (props.match.params.slug === 'adjectives') ? 1 : null,
      isConjunction: (props.match.params.slug === 'conjunctions') ? 1 : null,
    };
    return { variables };
  },
})(SwedishWords)));
