// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';

import { AllSwedishSentencesQuery } from '../../Lib/Queries';

import CommonList from './CommonList';

interface Props {
  data: Object
}

const SwedishSentences = (props: Props) => {
  const { data } = props;
  return (
    <CommonList
      data={data}
      queryName="allSwedishClauseList"
      queryList="clauses"
      classGenerator="SentenceClass"
    />
  );
};

export default withRouter(graphql(AllSwedishSentencesQuery, {
  options: (props) => {
    const variables = { slug: props.match.params.slug };
    return { variables };
  },
})(SwedishSentences));

// Example of how to call it without wrapping options:
// export default withRouter(graphql(
//   AllWordsQuery, { options: { variables: { first: '4', after: '' } } }
// ) (SwedisNouns));
// export default withRouter(graphql(AllSwedishSentencesQuery, {
//   options: (props) => {
//     const variables = { slug: props.match.params.slug };
//     return { variables };
//   },
// })(SwedishSentences));
