// @flow
import React, { Component } from 'react';

import {
  Row,
  Col,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';

interface State {
  show: bool,
}

interface Props {
  title: any,
  content: Array<*>,
}

class ContentInline extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    const {
      title,
      content,
    } = this.props;
    const { show } = this.state;

    // If there is no content to show, we just return, we do not print anything
    if (!content.length) {
      return null;
    }

    const body = show ? content : '...';
    const angle = show
      ? <FontAwesomeIcon icon={faAngleDown} />
      : <FontAwesomeIcon icon={faAngleRight} />;

    return (
      <Row>
        <Col>
          <span role="button" tabIndex={0} onClick={() => this.setState({ show: !show })} onKeyDown={() => this.setState({ show: !show })}>
            {angle}<b> {title}</b><i> ({content.length})</i>:
          </span>
          <span> {body}</span>
        </Col>
      </Row>
    );
  }
}

export default ContentInline;
