// @flow
import gql from 'graphql-tag';

const AllSwedishListsQuery = gql`
  query(
    $slug: String,
  ) {
    allSwedishWordsList(slug: $slug) {
      edges {
        node {
          slug
          name
          words {
            totalCount
            edges {
              node {
                id
                wordType
                isNoun
                isAdverb
                isVerb
                isAdjective
                isConjunction
                sv
                en1
                en2
                en3
                es1
                es2
                svSentencesExample
                svWordRelated
                svDef1
                svDef2
                svDef3
                svDef4
                svDef5
                svSyn
                svMp3
                svParts
                popStats
                svNounEndings
                svNounEndingsSvsv
                svNounEn
                svNounEtt
                svNounEttn
                svNounSingIndef
                svNounSingDefin
                svNounPluIndef
                svNounPluDefin
                svVerbEndings
                svVerbEndingsSvsv
                svVerbImperativ
                svVerbSupinum
                svVerbPresent
                svVerbPreteritum
                svVerbInfinitiv
                svVerbPresentParticip
                svVerbGroup
                svAdjEndings
                svAdjEndingsSvsv
                svAdjIndefSingEn
                svAdjIndefSingEtt
                svAdjDefinSing
                svAdjPlural
                svAdjComparativ
                svAdjSuperlativObest
                svAdjSuperlativBest
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

const AllSwedishSentencesQuery = gql`
  query(
    $slug: String,
  ) {
    allSwedishClauseList(slug: $slug) {
      edges {
        node {
          slug
          name
          clauses {
            edges {
              node {
                id
                en
                es
                sv
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const AllSwedishWordsQuery = gql`
  query(
    $after: String,
    $first: Int,
    $isNoun: Int,
    $isAdverb: Int,
    $isVerb: Int,
    $isAdjective: Int,
    $isConjunction: Int,
  ) {
    allSwedishWords(
      after: $after,
      first: $first,
      isNoun: $isNoun,
      isAdverb: $isAdverb,
      isVerb: $isVerb,
      isAdjective: $isAdjective,
      isConjunction: $isConjunction,
    ) {
      edges {
        node {
          id
          wordType
          isNoun
          isAdverb
          isVerb
          isAdjective
          isConjunction
          sv
          en1
          en2
          en3
          es1
          es2
          svSentencesExample
          svWordRelated
          svDef1
          svDef2
          svDef3
          svDef4
          svDef5
          popStats
          svNounEndings
          svNounEndingsSvsv
          svNounEn
          svNounEtt
          svNounEttn
          svNounSingIndef
          svNounSingDefin
          svNounPluIndef
          svNounPluDefin
          svVerbEndings
          svVerbEndingsSvsv
          svVerbImperativ
          svVerbSupinum
          svVerbPresent
          svVerbPreteritum
          svVerbInfinitiv
          svVerbPresentParticip
          svVerbGroup
          svAdjEndings
          svAdjEndingsSvsv
          svAdjIndefSingEn
          svAdjIndefSingEtt
          svAdjDefinSing
          svAdjPlural
          svAdjComparativ
          svAdjSuperlativObest
          svAdjSuperlativBest
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

// List content for "/swedish" page
const SwedishListQuery = gql`
  query {
    allSwedishWordsList{
      edges {
        node {
          slug
          name
          group
          words {
            totalCount
          }
        }
      }
      totalCount
    }
    allSwedishClauseList{
      edges {
        node {
          slug
          name
          clauses {
            totalCount
          }
        }
      }
      totalCount
    }
  }
`;

export {
  AllSwedishWordsQuery,
  AllSwedishListsQuery,
  AllSwedishSentencesQuery,
  SwedishListQuery,
};
