// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { CookiesProvider } from 'react-cookie';

import 'font-awesome/css/font-awesome.min.css';
import './index.css';

import Root from './Containers/Root';
import registerServiceWorker from './registerServiceWorker';

if (!process.env.REACT_APP_GRAPHQL) {
  throw new Error('No GraphQL url provided');
}

const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL,
    useGETForQueries: true,
    headers: {
      'Content-Type': 'text/html',
    },
  }),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first', // 'no-cache'
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-first', // 'no-cache'
      errorPolicy: 'all',
    },
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <CookiesProvider>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </CookiesProvider>
  </ApolloProvider>,
  (document.getElementById('root'): any),
);
registerServiceWorker();
