// @flow
import React, { Component } from 'react';

import { withCookies, Cookies } from 'react-cookie';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

import Select from 'react-select';

// import shuffle from 'lodash/shuffle';
import findIndex from 'lodash/findIndex';
import reverse from 'lodash/reverse';

import {
  Row,
  Col,
} from 'react-bootstrap';

import { rawSentenceInterface, renderObject } from '../PropsInterface';

import { renderPlainAsCloud } from '../Renders/FieldRenders';

import Progress from '../Blocks/Progress';
import WordButtons from '../Blocks/WordButtons';
import ShowAllTableRightLeft from '../Renders/ShowAllTableRightLeft';
import SentenceMain from '../RendersSentence/SentenceMain';
import SentenceShowHide from '../RendersSentence/SentenceShowHide';

interface Props {
  // Load cookies
  cookies: Cookies,
  // Imported
  raw: rawSentenceInterface,
  common: Function,
}

interface State {
  sentRender: renderObject,
  sentence: rawSentenceInterface, // no "parsed" sentence yet, is the same than raw
}

// List of different sentRender, in 'react-select' format.
// Label is what is going to be shown in the Dropdown.
// Value is not used (since we can not place the function there).
// The first one is the default one.
const sentRenderCollectionList = [
  {
    value: 'normal',
    label: 'Normal',
  },
  {
    value: 'show-english',
    label: 'Show English',
  },
  {
    value: 'show-swedish',
    label: 'Show Swedish',
  },
  {
    value: 'show-all',
    label: 'Show All',
  },
];

class SentenceClass extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { cookies } = props;
    this.state = {
      sentRender: sentRenderCollectionList[cookies.get('sentence-render') || 0],
      sentence: SentenceClass.getSentenceFromProps(props),
    };
  }

  componentDidUpdate(prevProps: Props) {
    // If current props and prevPros are the same, we do not need to change our state, just return.
    if (prevProps === this.props) return;
    this.updateStateIfNeed();
  }

  updateStateIfNeed() {
    this.setState({ sentence: SentenceClass.getSentenceFromProps(this.props) });
  }

  static getSentenceFromProps(properties: Props):rawSentenceInterface {
    const sentence = {
      id: properties.raw.id,
      en: properties.raw.en,
      es: properties.raw.es,
      sv: properties.raw.sv,
    };
    return sentence;
  }

  changeSentRender = (obj: Object) => {
    const { cookies } = this.props;
    const renderIndex = findIndex(sentRenderCollectionList, obj);
    cookies.set('sentence-render', renderIndex, { path: '/' });
    this.setState({ sentRender: sentRenderCollectionList[renderIndex] });
  }

  renderSentence = () => {
    const { sentRender, sentence } = this.state;
    const { en, es, sv } = sentence;

    // Access CommonList stuff,
    const { common } = this.props;
    const {
      state: {
        pageIndex,
        itemIndex,
        total,
        hasMoreToLoad,
        list,
      },
    } = common();
    const { prevItem, randomizeItem, nextItem } = common();

    const progressBlock = (
      <Progress
        pageIndex={pageIndex}
        itemsPerPage={5}
        itemIndex={itemIndex}
        total={total}
        hasMoreToLoad={hasMoreToLoad}
      />
    );

    const wordButtonsBlock = (
      <WordButtons
        itemIndex={itemIndex}
        prevItem={prevItem}
        randomizeItem={randomizeItem}
        nextPage={null}
        nextItem={nextItem}
      />
    );

    const left = renderPlainAsCloud(list.map((x) => x.props.raw.sv)).map((x) => [x]);
    const right = renderPlainAsCloud(list.map((x) => x.props.raw.en)).map((x) => [x]);

    reverse(left);
    reverse(right);

    switch (sentRender.value) {
      case 'normal':
        return (
          <React.Fragment>
            {progressBlock}
            <Row className="mt-3">
              <Col>
                <SentenceMain en={en} es={es} sv={sv} />
              </Col>
            </Row>
            {wordButtonsBlock}
          </React.Fragment>
        );
      case 'show-english':
        return (
          <React.Fragment>
            {progressBlock}
            <Row className="mt-3">
              <Col>
                <SentenceShowHide sent={en} hide={sv} />
              </Col>
            </Row>
            {wordButtonsBlock}
          </React.Fragment>
        );
      case 'show-swedish':
        return (
          <React.Fragment>
            {progressBlock}
            <Row className="mt-3">
              <Col>
                <SentenceShowHide sent={sv} hide={en} />
              </Col>
            </Row>
            {wordButtonsBlock}
          </React.Fragment>
          );
      case 'show-all':
        return <ShowAllTableRightLeft right={right} left={left} />;
      default:
        return false;
    }
  }

  // We can render the word in many different ways.
  // We have in props all the info from this Word
  // We pass as parameter 'sentRender', the function to render from RenderMain
  render() {
    const { sentRender } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <Select
              className="pg-word-render-select"
              name="form-field-name"
              value={sentRender}
              onChange={this.changeSentRender}
              options={sentRenderCollectionList}
            />
          </Col>
        </Row>
        {this.renderSentence()}
      </React.Fragment>
    );
  }
}

export default (withCookies(SentenceClass));
