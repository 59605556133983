// @flow
import React, { Component } from 'react';

import {
  Card,
  Accordion,
  Button,
} from 'react-bootstrap';

interface Props {
  title: string,
  body: any,
  event: number,
}

interface State {}

class WordCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, body, event } = this.props;

    // If nothing is sent as parameter in "body" (or is empty), we do not render the Card.Body
    // just the Card header.
    if (
      !body.props
      || (body.props.children && body.props.children.length === 0)
      || (body.props.words && body.props.words.length === 0)
    ) {
      return (
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey={event}>
              {title}
            </Accordion.Toggle>
          </Card.Header>
        </Card>
      );
    }

    return (
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={event}>
            {title}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={event}>
          <Card.Body>
            {body}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

export default WordCard;
