/* eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }] */
// @flow

// It loads more data from GraphQL
// If there is NO next page, return false
// dataValue is what we want to filter, like 'allClauses'
// variables are the query variables
// data is the GraphQL object
export default function GeneralNextPage(dataValue: string, variables: Object, data: Object) {
  data.fetchMore({
    variables,
    updateQuery: (previousResult, { fetchMoreResult }) => {
      const answer = {};
      const newEdges = fetchMoreResult[dataValue].edges;
      const { pageInfo } = fetchMoreResult[dataValue];
      if (newEdges.length) {
        answer[dataValue] = {
          __typename: previousResult[dataValue].__typename,
          edges: [...fetchMoreResult[dataValue].edges],
          pageInfo,
        };
        return answer;
      }
      return previousResult;
    },
  });
  return false;
}
