// @flow
import React, { Component } from 'react';

import {
  Card,
} from 'react-bootstrap';

import HideAndShow from '../Blocks/HideAndShow';

interface State {}

interface Props {
  sent: string,
  hide: string,
}

class SentenceShowHide extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { hide, sent } = this.props;

    return (
      <Card className="pg-sentence-show-hide">
        <Card.Body>
          <div className="main-sent">{sent}</div>
          <div className="show-button">
            <HideAndShow content={hide} />
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default SentenceShowHide;
