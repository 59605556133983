// @flow
import React, { Component } from 'react';

import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';

import { withCookies, Cookies } from 'react-cookie';

interface Props {
  cookies: Cookies,
}

interface State {
  itemsPerPage: number,
}

class User extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.updateItems = this.updateItems.bind(this);
    this.save = this.save.bind(this);
    const { cookies } = props;
    this.state = {
      itemsPerPage: cookies.get('settingsItemsPerPage') || 10,
    };
  }

  save: Function;

  updateFilter: Function;

  updateItems: Function;

  updateItems(event) {
    this.setState({ itemsPerPage: event.target.value });
  }

  save() {
    const { cookies } = this.props;
    const { itemsPerPage } = this.state;
    cookies.set('settingsItemsPerPage', itemsPerPage, { path: '/' });
  }

  render() {
    const { itemsPerPage } = this.state;
    return (
      <Container fluid>
        <Row>
          <Col>
            <p>User settings</p>
            <Form.Group controlId="formName">
              <Form.Label>Items per page</Form.Label>
              <Form.Control type="text" placeholder="Items per page" onChange={this.updateItems} value={String(itemsPerPage)} />
              <Button variant="primary" onClick={this.save}>Save</Button>
            </Form.Group>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withCookies(User);
