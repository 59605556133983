// @flow
import React, { Component } from 'react';

import {
  Card,
  ListGroup,
} from 'react-bootstrap';

interface State {}

interface Props {
  en?: string,
  es?: string,
  sv?: string,
}

class SentenceMain extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { en, es, sv } = this.props;

    return (
      <Card className="pg-sentence-main">
        <ListGroup variant="flush">
          { es ? (<ListGroup.Item><span className="flag-icon flag-icon-es" /> {es} </ListGroup.Item>) : (null) }
          { sv ? (<ListGroup.Item><span className="flag-icon flag-icon-sv" /> {sv} </ListGroup.Item>) : (null) }
          { en ? (<ListGroup.Item><span className="flag-icon flag-icon-en" /> {en} </ListGroup.Item>) : (null) }
        </ListGroup>
      </Card>
    );
  }
}

export default SentenceMain;
