// @flow
import React, { Component } from 'react';

import { withCookies, Cookies } from 'react-cookie';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

import Select from 'react-select';

// import shuffle from 'lodash/shuffle';
import findIndex from 'lodash/findIndex';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';

import {
  Row,
  Col,
} from 'react-bootstrap';

import WordHeader from '../RendersWord/WordHeader';
import WordAsCloud from '../RendersWord/WordAsCloud';
import WordAsList from '../RendersWord/WordAsList';
import WordAsMini from '../RendersWord/WordAsMini';
import PickWordBy from '../RendersWord/PickWordBy';

import ShowAllTableRightLeft from '../Renders/ShowAllTableRightLeft';

import type { wordObject, renderObject } from '../PropsInterface';

import { renderPlainAsCloud } from '../Renders/FieldRenders';

import remark from '../../../Lib/TextHandlers';

import {
  rawWordInterface,
  getWordTypeContent,
  extractLists,
  stringToArray,
} from '../PropsInterface';

import Progress from '../Blocks/Progress';
import WordButtons from '../Blocks/WordButtons';

interface Props {
  cookies: Cookies,
  raw: rawWordInterface,
  common: Function,
  itemsPerPage: number,
}

interface State {
  wordRender: renderObject,
  word: wordObject,
}

// List of different WordRenders, in 'react-select' format.
// Label is what is going to be shown in the Dropdown.
// Value is not used (since we can not place the function there).
// The first one is the default one.
const wordRenderCollectionList = [
  {
    value: 'cloud',
    label: 'Cloud',
  },
  {
    value: 'list',
    label: 'List',
  },
  {
    value: 'mini',
    label: 'Mini',
  },
  {
    value: 'pick-by-en',
    label: 'Pick by English',
  },
  {
    value: 'pick-by-sv',
    label: 'Pick by Swedish',
  },
  {
    value: 'pick-by-def',
    label: 'Pick by Definition',
  },
  {
    value: 'pick-by-es',
    label: 'Pick by Spanish',
  },
  {
    value: 'all-table',
    label: 'Show all',
  },
];

// Return the list of words we will replace in bold <b>word</b> version
// So it will be highlighted in the sentences example.
function getWordsReplace(raw: rawWordInterface) {
  const replaceWordsRaw = uniq([
    raw.svVerbImperativ,
    raw.svVerbInfinitiv,
    raw.svVerbPresent,
    raw.svVerbPresentParticip,
    raw.svVerbPreteritum,
    raw.svVerbSupinum,
    raw.svNounSingIndef,
    raw.svNounSingDefin,
    raw.svNounPluIndef,
    raw.svNounPluDefin,
    raw.svAdjIndefSingEn,
    raw.svAdjIndefSingEtt,
    raw.svAdjDefinSing,
    raw.svAdjPlural,
    raw.svAdjComparativ,
    raw.svAdjSuperlativObest,
    raw.svAdjSuperlativBest,
  ]);
  // Remove empty! and duplicates!
  return uniq(reject(replaceWordsRaw, isEmpty));
}

class WordClass extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { cookies } = props;
    this.state = {
      wordRender: wordRenderCollectionList[cookies.get('word-render') || 3],
      word: WordClass.getStateWordFromProps(props),
    };
  }

  componentDidUpdate(prevProps: Props) {
    // If current props and prevPros are the same, we do not need to change our state, just return.
    if (prevProps === this.props) return;
    this.updateStateIfNeed();
  }

  updateStateIfNeed() {
    this.setState({ word: WordClass.getStateWordFromProps(this.props) });
  }

  static getStateWordFromProps(properties: Props):wordObject {
    const { raw } = properties;
    // Getting raw values
    const enList = extractLists(raw, ['en1', 'en2', 'en3']);
    const esList = extractLists(raw, ['es1', 'es2']);
    const svDefsList = extractLists(raw, ['svDef1', 'svDef2', 'svDef3', 'svDef4', 'svDef5']);
    const svSentencesList = remark(extractLists(raw, ['svSentencesExample']), getWordsReplace(raw));
    const svRelatedList = extractLists(raw, ['svWordRelated']);
    const svSynList = extractLists(raw, ['syn']);
    // Other array values
    const wordType = stringToArray(raw.wordType);
    const svParts = stringToArray(raw.svParts);
    const svMP3 = stringToArray(raw.svMp3);
    // Values that we do not need to parse
    const {
      sv,
      isAdjective,
      isAdverb,
      isConjunction,
      isNoun,
      isVerb,
    } = raw;

    const word = {
      // Plain
      enPlain: uniq(enList),
      esPlain: uniq(esList),
      svDefsPlain: uniq(svDefsList),
      svSentencesPlain: uniq(svSentencesList),
      svRelatedPlain: uniq(svRelatedList),
      svSynPlain: uniq(svSynList),
      // Rendered
      enRender: renderPlainAsCloud(enList),
      esRender: renderPlainAsCloud(esList),
      svDefsRender: renderPlainAsCloud(svDefsList),
      // Specific content
      specific: getWordTypeContent(raw),
      popStats: raw.popStats,
      svParts,
      wordType,
      svMP3,
      // No parsing
      sv,
      isAdjective,
      isAdverb,
      isConjunction,
      isNoun,
      isVerb,
      verb: {
        imperativ: raw.svVerbImperativ,
        infinitiv: raw.svVerbInfinitiv,
        present: raw.svVerbPresent,
        presentPart: raw.svVerbPresentParticip,
        preteritum: raw.svVerbPreteritum,
        supinum: raw.svVerbSupinum,
      },
    };
    return word;
  }

  changeWordRender = (obj: Object) => {
    const { cookies } = this.props;
    const renderIndex = findIndex(wordRenderCollectionList, obj);
    cookies.set('word-render', renderIndex, { path: '/' });
    this.setState({ wordRender: wordRenderCollectionList[renderIndex] });
  }

  renderWord = () => {
    const { wordRender, word } = this.state;
    const { itemsPerPage } = this.props;

    // Access CommonList stuff,
    const { common } = this.props;
    const {
      state: {
        pageIndex,
        itemIndex,
        total,
        hasMoreToLoad,
        list,
      },
    } = common();

    const { prevItem, randomizeItem, nextItem } = common();

    const progressBlock = (
      <Progress
        pageIndex={pageIndex}
        itemsPerPage={itemsPerPage}
        itemIndex={itemIndex}
        total={total}
        hasMoreToLoad={hasMoreToLoad}
      />
    );

    const wordButtonsBlock = (
      <WordButtons
        itemIndex={itemIndex}
        prevItem={prevItem}
        randomizeItem={randomizeItem}
        nextPage={null}
        nextItem={nextItem}
      />
    );

    // const rowList = list.map((x) => x.props.raw);
    const rowListParsed = list.map((x) => WordClass.getStateWordFromProps(x.props));
    const allEn = list.map((x) => WordClass.getStateWordFromProps(x.props).enRender);
    const alSv = list.map((x) => renderPlainAsCloud([WordClass.getStateWordFromProps(x.props).sv]));

    // Get word from element 2 in this Graphql batch:
    // const parentList = this.props.wordList();
    // console.log(WordClass.getStateWordFromProps(parentList[2].props));
    switch (wordRender.value) {
      case 'cloud':
        return (
          <React.Fragment>
            {progressBlock}
            <WordHeader word={word} />
            <WordAsCloud word={word} />
            {wordButtonsBlock}
          </React.Fragment>
        );
      case 'list':
        return (
          <React.Fragment>
            {progressBlock}
            <WordHeader word={word} />
            <WordAsList word={word} />
            {wordButtonsBlock}
          </React.Fragment>
        );
      case 'mini':
        return (
          <React.Fragment>
            {progressBlock}
            <WordHeader word={word} />
            <WordAsMini word={word} />
            {wordButtonsBlock}
          </React.Fragment>
        );
      case 'pick-by-en':
        return (
          <React.Fragment>
            {progressBlock}
            <PickWordBy word={word} alternatives={rowListParsed} toShow="sv" toList="enPlain" toHelp="svDefsRender" />
            {wordButtonsBlock}
          </React.Fragment>
        );
      case 'pick-by-sv':
        return (
          <React.Fragment>
            {progressBlock}
            <PickWordBy word={word} alternatives={rowListParsed} toShow="enPlain" toList="sv" toHelp="" />
            {wordButtonsBlock}
          </React.Fragment>
        );
      case 'pick-by-def':
        return (
          <React.Fragment>
            {progressBlock}
            <PickWordBy word={word} alternatives={rowListParsed} toShow="svDefsRender" toList="sv" toHelp="" />
            {wordButtonsBlock}
          </React.Fragment>
        );
      case 'pick-by-es':
        return (
          <React.Fragment>
            {progressBlock}
            <PickWordBy word={word} alternatives={rowListParsed} toShow="sv" toList="esRender" toHelp="" />
            {wordButtonsBlock}
          </React.Fragment>
        );
      case 'all-table':
        return <ShowAllTableRightLeft left={alSv} right={allEn} />;
      default:
        return false;
    }
  }

  // We can render the word in many different ways.
  // We have in props all the info from this Word
  // We pass as parameter 'wordRender', the function to render from RenderMain
  render() {
    const { wordRender } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <Select
              className="pg-word-render-select"
              name="form-field-name"
              value={wordRender}
              onChange={this.changeWordRender}
              options={wordRenderCollectionList}
            />
          </Col>
        </Row>
        <div className="pg-container-pre-button">
          {this.renderWord()}
        </div>
      </React.Fragment>
    );
  }
}

export default (withCookies(WordClass));
