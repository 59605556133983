// @flow
import React, { Component } from 'react';

import {
  Row,
  Col,
} from 'react-bootstrap';

import PGLogo from '../../Assets/patataclara.svg';
import flagSE from '../../Assets/flag-se.svg';
import flagGB from '../../Assets/flag-gb.svg';
import flagES from '../../Assets/flag-es.svg';

interface Props {}
interface State {}

class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return ([
      <Row key="1">
        <Col>
          <h1 className="text-center">PatataGrammar.com</h1>
          <p className="text-center">Always developing, <small>always learning</small></p>
        </Col>
      </Row>,
      <Row key="2">
        <Col className="header-flags">
          <img src={flagGB} alt="PatataGrammar Logo" />
          <img src={flagSE} alt="PatataGrammar Logo" />
          <img src={flagES} alt="PatataGrammar Logo" />
        </Col>
      </Row>,
      <Row key="3">
        <Col>
          <div className="pg-main-logo text-center">
            <img src={PGLogo} alt="PatataGrammar Logo" />
          </div>
        </Col>
      </Row>,
      <Row key="4">
        <Col>
          <p className="text-center">admin@patatagrammar.com</p>
        </Col>
      </Row>,
    ]);
  }
}

export default Home;
