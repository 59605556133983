// @flow

// Main page, /swedish
// Here we show different tabs (Vocabulary, Sentences..), each tab with different content.

import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { graphql } from 'react-apollo';

import filter from 'lodash/filter';

import {
  Row,
  Col,
  Spinner,
  Nav,
  NavItem,
  Tab,
  Tabs,
  Accordion,
  Card,
} from 'react-bootstrap';

import { SwedishListQuery } from '../../Lib/Queries';

import CustomPopover from '../Components/CustomPopover';

import PageTitle from './Blocks/PageTitle';

interface Props {
  data: Object,
  history: Object,
  location: Object,
  match: Object,
}

interface State {
  loading: boolean
}

class Swedish extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // We can not set "true" by default, if change tab it will get stuck loading
    this.state = {
      loading: props.data.loading,
    };
  }

  // This method will be called once we have received the data from the GraphQL
  componentDidUpdate(prevProps: Props, prevState) {
    // If current props and prevPros are the same, we do not need to change our state, just return.
    if (prevProps === this.props) return;
    this.updateStateIfNeed(prevProps, prevState);
  }

  updateStateIfNeed(prevProps: Props, prevState) {
    const { data } = this.props;

    // Data is loaded (loading changes from true to false).
    if (prevState.loading && !data.loading) {
      this.setState({ loading: false });
    }
  }

  render() {
    // For SE word lists, we have different groups ("songs", "nyheter"...)
    // Group each group under one accordion
    const seWordsGroup = (edges) => {
      const groupsAll = edges.map((x) => x.node.group);
      const groupsUnique = [...new Set(groupsAll)];

      // Each group (with the list inside)
      const groupsRndr = groupsUnique.map((x) => {
        // Find all list with give group
        const lists = filter(edges, (y) => y.node.group === x);
        // Create navitem por list group
        const listRndr = lists.map((ob) => (
          <NavItem key={ob.node.slug}>
            <NavLink to={`/swedish/lists/${ob.node.slug}`} activeClassName="selected">
              {ob.node.name} ({ob.node.words.totalCount} words)
            </NavLink>
          </NavItem>
        ));
        // For the group "null", give a different name!
        const group = x || 'Not group assigned';
        // Now embed each group on its own Accordion card
        return (
          <Card key={group}>
            <Accordion.Toggle as={Card.Header} eventKey={group}>
              {group}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={x}>
              <Card.Body>
                <Nav className="flex-column">
                  {listRndr}
                </Nav>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      });

      return groupsRndr;
    };

    const seClausesGroup = (edges) => {
      const clausesNavItem = edges.map((ob) => (
        <NavItem key={ob.node.slug}>
          <NavLink to={`/swedish/sentences/${ob.node.slug}`} activeClassName="selected">
            {ob.node.name} ({ob.node.clauses.totalCount})
          </NavLink>
        </NavItem>
      ));
      return (
        <Nav className="flex-column">
          {clausesNavItem}
        </Nav>
      );
    };

    const innerContent = () => {
      const { loading } = this.state;
      if (loading) return <Spinner animation="border" />;
      const { data: { allSwedishWordsList, allSwedishClauseList } } = this.props;
      if (!allSwedishWordsList && !allSwedishClauseList) {
        return <Spinner animation="border" />;
      }
      return (
        <Tabs defaultActiveKey="first">
          <Tab eventKey="zero" title="Vocabulary" className="m-3">
            <h4>Swedish Vocabulary</h4>
            <Nav className="flex-column">
              <NavItem>
                <NavLink to="/swedish/words/nouns" activeClassName="selected">Nouns</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/swedish/words/verbs" activeClassName="selected">Verbs</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/swedish/words/adjectives" activeClassName="selected">Adjectives</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/swedish/words/adverbs" activeClassName="selected">Adverbs</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/swedish/words/adverbs" activeClassName="selected">All</NavLink>
              </NavItem>
            </Nav>
          </Tab>
          <Tab eventKey="first" title="Word Lists" className="m-3">
            <h4>Swedish Word Lists</h4>
            <Accordion>
              {seWordsGroup(allSwedishWordsList.edges)}
              <Card key="all-words">
                <Accordion.Toggle as={Card.Header} eventKey="all-words">
                  All Words <CustomPopover place="top" msg="Special list that contains all the words" />
                </Accordion.Toggle>
              </Card>
            </Accordion>
          </Tab>
          <Tab eventKey="second" title="Sentences" className="m-3">
            <h4>Swedish Sentences Lists</h4>
            <Accordion>
              {seClausesGroup(allSwedishClauseList.edges)}
            </Accordion>
          </Tab>
        </Tabs>
      );
    };

    return (
      <React.Fragment>
        <PageTitle title="Swedish Language" />
        <Row>
          <Col>
            {innerContent()}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(graphql(SwedishListQuery)(Swedish));
