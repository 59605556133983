// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';

import { AllSwedishListsQuery } from '../../Lib/Queries';

import CommonList from './CommonList';

interface Props {
  data: Object
}

const SwedishLists = (props: Props) => {
  const { data } = props;
  return (
    <CommonList
      data={data}
      queryName="allSwedishWordsList"
      queryList="words"
      classGenerator="WordClass"
    />
  );
};

export default withRouter(graphql(AllSwedishListsQuery, {
  options: (props) => {
    const variables = { slug: props.match.params.slug };
    return { variables };
  },
})(SwedishLists));

// Example of how to call it without wrapping options:
// export default withRouter(graphql(
//   AllWordsQuery, { options: { variables: { first: '4', after: '' } } }
// ) (SwedisNouns));
