// @flow
import React, { Component } from 'react';

import {
  Card,
  Row,
  Col,
  Badge,
} from 'react-bootstrap';

import type { wordObject } from '../PropsInterface';

interface State {}

interface Props {
  word: wordObject,
}

class WordHeader extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      word: {
        wordType,
        svParts,
        popStats,
        sv,
      },
    } = this.props;
    const badgesArray = wordType;
    const badgesList = badgesArray.map((x) => (<Badge key={x} variant="dark">{x}</Badge>));
    return (
      <Card.Body className="pg-card-body">
        <Row className="pg-card-head">
          <Col>
            {badgesList}
          </Col>
          <Col className="text-center">
            <Badge variant="light">{svParts}</Badge>
          </Col>
          <Col className="text-right">
            <Badge variant="success">{popStats}</Badge>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="pg-word text-center">{sv}</h5>
          </Col>
        </Row>
      </Card.Body>
    );
  }
}

export default WordHeader;
