// @flow
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  Container,
} from 'react-bootstrap';

import usePageTracking from './usePageTracking';

import SwedishWords from './Swedish/SwedishWords';
import SwedishLists from './Swedish/SwedishLists';
import SwedishSentences from './Swedish/SwedishSentences';
import Swedish from './Swedish/Swedish';
import Home from './Home/Home';
import NavMenu from './Menu';
import User from './User/User';

const Root = () => {
  const mainStyle = { margin: '0 auto', maxWidth: '1000px' };
  usePageTracking();
  return (
    <div>
      <NavMenu />
      <main style={mainStyle}>
        <Container fluid>
          <Switch>
            <Route exact path="/user" component={() => <User />} />
            <Route exact path="/swedish" component={Swedish} />
            <Route exact path="/swedish/words/:slug" component={SwedishWords} />
            <Route exact path="/swedish/lists/:slug" component={SwedishLists} />
            <Route exact path="/swedish/sentences/:slug" component={SwedishSentences} />
            <Route path="/" component={Home} />
          </Switch>
        </Container>
      </main>
    </div>
  );
};

export default Root;
