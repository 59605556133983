// @flow
import React, { Component } from 'react';

import { renderCloudAsSingleLine, renderPlainAsSingleLine, getWordSpecificAsCustomTable } from '../Renders/FieldRenders';
import ContentInline from '../Blocks/ContentInline';

import type { wordObject } from '../PropsInterface';

interface State {}

type Props = {
  word: wordObject,
}

class WordAsMini extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      word: {
        enRender,
        esRender,
        svDefsRender,
        svRelatedPlain,
        svSentencesPlain,
        svSynPlain,
        specific,
      },
    } = this.props;

    return (
      <React.Fragment>
        <ContentInline title="Spanish" content={renderCloudAsSingleLine(esRender)} />
        <ContentInline title="English" content={renderCloudAsSingleLine(enRender)} />
        <ContentInline title="Definitions" content={renderCloudAsSingleLine(svDefsRender)} />
        <ContentInline title="Related" content={renderPlainAsSingleLine(svRelatedPlain)} />
        <ContentInline title="Sentences" content={renderPlainAsSingleLine(svSentencesPlain)} />
        <ContentInline title="Syn" content={renderPlainAsSingleLine(svSynPlain)} />
        <ContentInline title="Custom" content={getWordSpecificAsCustomTable(specific)} />
      </React.Fragment>
    );
  }
}

export default WordAsMini;
