// @flow
import React, { Component } from 'react';

import ReactWordcloud from 'react-wordcloud';

import {
  Accordion,
} from 'react-bootstrap';

// import { getWordSpecificAsTable } from './FieldRenders';
import WordCard from '../WordCard';

import type { wordObject } from '../PropsInterface';

import { renderPlainAsCloud, renderSpecificAsCloud } from '../Renders/FieldRenders';

interface State {}

interface Props {
  word: wordObject,
}

// Using Accordion we need to know the width/height of the wordcloud, otherwise it will not work.
// we get the display width and remove the margins
function getCloudWidth() {
  const width = window.innerWidth - 72;
  if (width > 928) return 928;
  return width;
}

class WordAsCloud extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      word: {
        enRender,
        esRender,
        svDefsRender,
        svRelatedPlain,
        svSentencesPlain,
        svSynPlain,
        specific,
      },
    } = this.props;

    const wordCloudOptions = {
      rotations: 2,
      rotationAngles: [0, 0],
      enableTooltip: false,
      deterministic: false,
      transitionDuration: 0,
      fontSizes: [16, 32],
      padding: 2,
     };

    const wordCloudSize = [getCloudWidth(), 300];

    return (
      <Accordion defaultActiveKey="0">
        <WordCard
          title={`Spanish (${esRender.length})`}
          body={<ReactWordcloud words={esRender} options={wordCloudOptions} size={wordCloudSize} className="card-body-cloud" />}
          event={99}
        />
        <WordCard
          title={`English (${enRender.length})`}
          body={<ReactWordcloud words={enRender} options={wordCloudOptions} size={wordCloudSize} className="card-body-cloud" />}
          event={98}
        />
        <WordCard
          title={`Definitions (${svDefsRender.length})`}
          body={<ReactWordcloud words={svDefsRender} options={wordCloudOptions} size={wordCloudSize} className="card-body-cloud" />}
          event={97}
        />
        <WordCard
          title={`Sentences example (${svSentencesPlain.length})`}
          body={<ReactWordcloud words={renderPlainAsCloud(svSentencesPlain)} options={wordCloudOptions} size={wordCloudSize} className="card-body-cloud" />}
          event={96}
        />
        <WordCard
          title={`Related words (${svRelatedPlain.length})`}
          body={<ReactWordcloud words={renderPlainAsCloud(svRelatedPlain)} options={wordCloudOptions} size={wordCloudSize} className="card-body-cloud" />}
          event={95}
        />
        <WordCard
          title={`Syn words (${svSynPlain.length})`}
          body={<ReactWordcloud words={renderPlainAsCloud(svSynPlain)} options={wordCloudOptions} size={wordCloudSize} className="card-body-cloud" />}
          event={94}
        />
        <WordCard
          title={`specific words (${specific.length})`}
          body={<ReactWordcloud words={renderSpecificAsCloud(specific)} options={wordCloudOptions} size={wordCloudSize} className="card-body-cloud" />}
          event={93}
        />
      </Accordion>
    );
  }
}

export default WordAsCloud;
