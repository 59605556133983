// @flow
import React, { Component } from 'react';

import {
  Row,
  Col,
  ProgressBar,
} from 'react-bootstrap';

interface State {}

interface Props {
  pageIndex: number,
  itemsPerPage: number,
  itemIndex: number,
  total: number,
  hasMoreToLoad: bool,
}

class Progress extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      pageIndex,
      itemsPerPage,
      itemIndex,
      total,
      hasMoreToLoad,
    } = this.props;

    // pageIndex starts in 1
    // If hasMoreToLoad is set to false (no more to load), then:
    // - Current item is just itemIndex + 1 (no pages involved)
    // - Remaining items do not consider pages.
    const currentItem = hasMoreToLoad
      ? ((pageIndex - 1) * itemsPerPage) + itemIndex + 1
      : itemIndex + 1;
    const remainingInPage = hasMoreToLoad
      ? (itemsPerPage - itemIndex - 1)
      : (total - itemIndex - 1);

    return (
      <Row className="progressbar mt-3">
        <Col>
          <div className="text-muted small">{ currentItem } / { total } ({ itemsPerPage }) </div>
        </Col>
        <Col>
          <ProgressBar>
            <ProgressBar striped variant="success" now={currentItem} key={1} min={0} max={total} />
            <ProgressBar striped variant="warning" now={remainingInPage} key={2} min={0} max={total} />
          </ProgressBar>
        </Col>
      </Row>
    );
  }
}

export default Progress;
