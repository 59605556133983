// @flow
import React, { Component } from 'react';

import { Link, NavLink } from 'react-router-dom';

import {
  Navbar,
  Nav,
} from 'react-bootstrap';

interface Props { }

interface State { }

class NavMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <Navbar bg="dark-background" expand="sm">
        <Navbar.Brand>
          <Nav.Link className="text-white" as={Link} to="/">
            <span id="top-menu-brand">
              <span className="first">P</span>
              <span className="rest">atata</span>
              <span className="first">G</span>
              <span className="rest">rammar</span>
            </span>
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="pg-navbar-nav" />
        <Navbar.Collapse id="pg-navbar-nav">
          <Nav className="mr-auto">
            <NavLink to="/swedish" className="nav-link">Swedish</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavMenu;
