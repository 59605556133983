// @flow
import React, { Component } from 'react';

import {
  Accordion,
} from 'react-bootstrap';

import { renderCloudAsList } from '../Renders/FieldRenders';
import WordCard from '../WordCard';

import type { wordObject } from '../PropsInterface';

interface State {}

interface Props {
  word: wordObject,
}

class WordAsList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      word: {
        esRender,
      },
    } = this.props;

    return (
      <Accordion defaultActiveKey="0">
        <WordCard
          title={`Spanish (${esRender.length})`}
          body={renderCloudAsList(esRender)}
          event={94}
        />
      </Accordion>
    );
  }
}

export default WordAsList;
