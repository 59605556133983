// @flow

// We send:
// An array sem like ["föräldrarna håller henne"] (sentences we are working to work with)
// An array rem like ["hålla", "håller", "hållande"] (words to replace with <b>word</b> version)
// We expect: ["föräldrarna <b>håller </b>henne"]
export default function remark(sent: Array<string>, rem: Array<string>): Array<string> {
  // Iterate sentences array
  const result = sent.map((s: string):string => {
    let res = s;
    // We have tried many solutions, first with RegEx
    // rem.map((r) => res = res.replace(new RegExp(`\\b${r}\\b`, 'gi'), '<b>'+r+'</b>'));
    // This does not work with words that contains åäö and other werid characters.
    // For example for word 'är' it will pick 'h<b>är</b> är vi!"', that is wrong! More info:
    // https://stackoverflow.com/questions/11704182/regex-with-extended-latin-alphabet-%C3%A4-%C3%B6-%C3%BC-%C3%A8-%C3%9F

    // Using regex can be an issue, for example, if we use something like:
    // const regex = new RegExp(`(\\W\|^\| )${r}(\\W\|$\| )`, 'gi');
    // res = res.replace(regex1, '<b>'+r+'</b>');
    // We may have an issue with spaces, we do not know if it is included or not!
    // So many words will show together.

    // Last try: do a manual replace of all the items:
    rem.map((r: string):null => {
      // Middle
      // $FlowFixMe
      res = res.replaceAll(` ${r} `, ` <b>${r}</b> `);
      res = res.replaceAll(` ${r} `, ` <b>${r}</b> `);
      // Twice, not an error! Fix scenarios where word is duplicated
      res = res.replaceAll(` ${r}.`, ` <b>${r}</b>.`);
      res = res.replaceAll(`.${r} `, `.<b>${r}</b> `);
      res = res.replaceAll(` ${r}?`, ` <b>${r}</b>?`);
      res = res.replaceAll(` ${r},`, ` <b>${r}</b>,`);
      // Starting
      res = res.replace(new RegExp(`(^)${r} `, 'gi'), `<b>${r}</b> `);
      res = res.replace(new RegExp(`(^)${r}, `, 'gi'), `<b>${r}</b>, `);
      // Ending
      res = res.replace(new RegExp(` ${r}($)`, 'gi'), ` <b>${r}</b>`);
      // We are changing res string, but we need to return something to fix tests
      return null;
    });
    return res;
  });
  return result;
}
