// @flow
import React, { Component } from 'react';

import {
  ListGroup,
  Alert,
  Card,
} from 'react-bootstrap';

import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import intersection from 'lodash/intersection';
import compact from 'lodash/compact';
import shuffle from 'lodash/shuffle';
import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';

import type { wordObject } from '../PropsInterface';

import { renderCloudAsSingleLine, renderPlainAsSingleLine } from '../Renders/FieldRenders';

interface Props {
  word: wordObject,
  alternatives: Array<wordObject>,
  toShow: string,
  toList: string,
  toHelp: string,
}

interface State {
  selected: Object, // it is a wordObject (or null if nothing selected yet)
  candidates: Array<wordObject>,
}

class PickWordBy extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { word, alternatives, toList } = props;
    this.getCandidates = this.getCandidates.bind(this);
    this.state = {
      selected: null,
      candidates: this.getCandidates(alternatives, word, toList),
    };
  }

  componentDidUpdate(prevProps: Props) {
    // If current props and prevPros are the same, we do not need to change our state, just return.
    if (prevProps === this.props) return;
    this.updateStateIfNeed();
  }

  getCandidates = (alternatives: Array<*>, word:Object, toList: string) => {
    // Remove current word, so when we add it again to the candidates, we would not
    // have it twice (if it is elected as candidate)
    const alternativesWithoutWord = reject(alternatives, { sv: word.sv });
    // Filter values from alternatives
    const filterOne = alternativesWithoutWord.map((x) => {
      // Candidate should have same type than word (at least one)
      const wordInter = intersection(word.wordType, x.wordType);
      // Candidates should have also non null values to show!
      if (x[toList].length > 0 && wordInter.length > 0) return x;
      return null;
    });
    // Remove null values (what if less than 5?)
    const filterTwo = compact(filterOne);
    const filterThree = shuffle(filterTwo).slice(0, 5);
    const candidates = shuffle([...filterThree, word]);
    return candidates;
  }

  updateStateIfNeed() {
    const { word, alternatives, toList } = this.props;
    this.setState({
      selected: null,
      candidates: this.getCandidates(alternatives, word, toList),
    });
  }

  render() {
    const {
      word,
      toShow,
      toList,
      toHelp,
    } = this.props;
    const { selected, candidates } = this.state;

    // When we render a word field, it can be a string (like 'sv' content -> 'bok')
    // or an array (like 'english' content -> ['seed', 'grain', 'germ'])
    const renderItem = (item) => {
      if (isString(item)) return item;
      if (isArray(item) && item.length > 0) {
        // Array can be: objects ({ text: "en mycket stor skara", value: 16 }) or strings
        if (isObject(item[0])) return renderCloudAsSingleLine(item);
        if (isString(item[0])) return renderPlainAsSingleLine(item);
      }
      return null;
    };

    const getStatus = () => {
      if (!selected) {
        return (
          <Alert variant="secondary">
            Pick the right one!
          </Alert>
        );
      }
      // Comparison can be between strings or arrays!
      // 'sv' will be a string, but 'english' will be an array
      if (isEqual(selected[toShow], word[toShow])) {
        return (
          <Alert variant="success">
            <Alert.Heading>You are right!</Alert.Heading>
            <b>{renderItem(selected[toList])}</b> --&gt; <b>{renderItem(selected[toShow])}</b>
          </Alert>
        );
      }
        return (
          <Alert variant="danger">
            <Alert.Heading>Nop!</Alert.Heading>
            <b>{renderItem(selected[toList])}</b> --&gt; <b>{renderItem(selected[toShow])}</b>
          </Alert>
        );
    };

    const listItems = candidates.map(
      (alt) => (
        <ListGroup.Item className="pg-list-pickup-item" key={alt.sv} action onClick={() => this.setState({ selected: alt })}>
          {renderItem(alt[toList])}
        </ListGroup.Item>
      ),
    );

    // If there is no toList attribute in word, then render different message.
    // otherwise it would print an empty box in the options!
    if (isEmpty(word[toList])) {
      return (
        <div className="pickby-container">
          <Card body className="m-1">
            <h5 className="text-center">{renderItem(word[toShow])}</h5>
            <div>{renderItem(word[toHelp])}</div>
            <div> This word has an empty value. Try the next one. </div>
          </Card>
        </div>
      );
    }

    return (
      <div className="pickby-container">
        <Card body className="m-1">
          <h5 className="text-center">{renderItem(word[toShow])}</h5>
          <div>{renderItem(word[toHelp])}</div>
          <div>{getStatus()}</div>
        </Card>
        <ListGroup className="pg-list-pickup-container">
          {listItems}
        </ListGroup>
      </div>
    );
  }
}

export default PickWordBy;
