// @flow
import React, { Component } from 'react';

import {
  Spinner,
} from 'react-bootstrap';

import shuffle from 'lodash/shuffle';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

import PageTitle from './Blocks/PageTitle';

import WordClass from './Classes/WordClass';
import SentenceClass from './Classes/SentenceClass';
// import VerbClass from './Classes/VerbClass';

import GeneralNextPage from '../../Lib/Helpers';

/*
 * This class is the general Wrapper for a Query.
 * It collects the query output and iterate the list, creating objects with class
 * (WordClass, SentenceClass), and we render these objects.
 * To work inside these classes, we inject common={this.getThis} to theses classes.
 * So, inside the class, we can get the list, for example:
 *   const commonParent = common();
 *   const listHTML = commonParent.state.list.map(...);
 */

interface Props {
  data: Object,
  queryName: string,
  queryList: string,
  classGenerator: string,
}

interface State {
  list: Array<Object>, // can be WordClass or SentenceClass
  total: number,
  loading: boolean,
  done: boolean,
  itemIndex: number, // iteration over current page
  hasMoreToLoad: bool,
  cursor: string,
  pageIndex: number,
  itemsPerPage: number,
}

class CommonList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.nextItem = this.nextItem.bind(this);
    this.getThis = this.getThis.bind(this);
    this.state = this.getStateFromProps(props);
  }

  // This method will be called once we have received the data from the GraphQL
  componentDidUpdate(prevProps: Props, prevState: State) {
    // If current props and prevPros are the same, we do not need to change our state, just return.
    if (prevProps === this.props) return;
    this.updateStateIfNeed(prevProps, prevState);
  }

  updateStateIfNeed(prevProps: Props, prevState: State) {
    const { data } = this.props;
    // Data is loaded (loading changes from true to false).
    if (prevState.loading && !data.loading) {
      this.setState(this.getStateFromProps(this.props));
    }
  }

  // This is called in the constructor (first time or changing tab), and once data is loaded.
  getStateFromProps(props: Props):State {
    const {
      data,
      queryName,
      queryList,
      classGenerator,
    } = props;

    const itemsPerPage = data.variables.first;

    function getRawList(dataProps, qName, qList) {
      if (dataProps[qName].edges.length > 1) return dataProps[qName].edges;
      return dataProps[qName].edges[0].node[qList].edges;
    }

    function getPageIdx(thisObject) {
      if (has(thisObject, 'state') && has(thisObject.state, 'pageIndex')) {
        return thisObject.state.pageIndex;
      }
      return 0;
    }

    function getTotal(list) {
      if (has(data, queryName)) {
        if (has(data[queryName].edges[0].node, queryList)) {
          if (has(data[queryName].edges[0].node[queryList], 'totalCount')) {
            return data[queryName].edges[0].node[queryList].totalCount;
          }
          return list.length;
        }
        // data[queryName].totalCount
        if (has(data[queryName], 'totalCount')) {
          return data[queryName].totalCount;
        }
      }
      return list.length;
    }

    if (data[queryName]) {
      const list = getRawList(data, queryName, queryList).map(
        (ob) => {
          switch (classGenerator) {
            case 'SentenceClass': return <SentenceClass raw={ob.node} common={this.getThis} />;
            case 'WordClass': return <WordClass raw={ob.node} common={this.getThis} itemsPerPage={itemsPerPage} />;
            // case 'VerbClass': return <VerbClass raw={ob.node} common={this.getThis} />;
            default: return null;
          }
        },
      );
      // If we have a valid totalCount, we take it, otherwise we take the list length.
      const total = getTotal(list);
      // const total = getTotal(data, queryName, queryList, list);

      // If we have state, take pageIndex from there, otherwise then pageIndex = 0
      const pageIdx = getPageIdx(this); // eslint-disable-line
      return {
        list,
        total,
        loading: props.data.loading,
        done: false,
        itemIndex: 0,
        cursor: data[queryName].pageInfo.endCursor,
        hasMoreToLoad: data[queryName].pageInfo.hasNextPage,
        pageIndex: pageIdx + 1,
        itemsPerPage,
      };
    }

    return {
      list: [],
      total: 0,
      loading: props.data.loading,
      done: false,
      itemIndex: 0,
      pageIndex: 0,
      cursor: '',
      itemsPerPage,
      hasMoreToLoad: true,
    };
  }

  getThis = () => this;

  nextPage = () => {
    const { hasMoreToLoad, cursor, itemsPerPage } = this.state;
    const { data, queryName } = this.props;
    if (hasMoreToLoad) {
      this.setState({ loading: true });
      const queryVariables = {
        first: itemsPerPage,
        after: cursor,
      };
      GeneralNextPage(queryName, queryVariables, data);
    }
  }

  nextItem = () => {
    const { itemIndex, hasMoreToLoad, list } = this.state;
    const newIndex = itemIndex + 1;

    // If we reach the limit
    if (newIndex >= list.length) {
      // Load (if we have more)
      if (hasMoreToLoad) {
        this.nextPage();
        return;
      }
      this.setState({ done: true });
      return;
    }
    this.setState({ itemIndex: newIndex });
  }

  prevItem = () => {
    const { itemIndex } = this.state;
    const newIndex = itemIndex - 1;
    this.setState({ itemIndex: newIndex });
  }

  randomizeItem = () => {
    const { list } = this.state;
    const newList = shuffle(list);
    this.setState({ list: newList });
  }

  render() {
    const {
      loading,
      done,
      list,
      itemIndex,
    } = this.state;

    if (loading) return <Spinner animation="border" />;

    const { data, queryName } = this.props;
    const { edges } = data[queryName];

    // If edges is empty, the collection do not exist. This means we are visiting a wrong link
    if (isEmpty(edges)) return <PageTitle title="Swedish List" body="Collection do not exist" />;

    // We are done with current list
    if (done) return <PageTitle title="Swedish List" body="We are done!" />;

    const Current = list[itemIndex];
    if (!Current) {
      return <p> No items </p>;
    }

    return (
      <React.Fragment>
        <PageTitle title="Swedish List" sub={edges[0].node.name} />
        {Current}
      </React.Fragment>
    );
  }
}

export default CommonList;
