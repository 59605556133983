// @flow
import React, { Component } from 'react';

import {
  Button,
} from 'react-bootstrap';

interface Props {
  content: string,
}
interface State {
  buttonClicked: bool,
}

class HideAndShow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      buttonClicked: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    // If current props and prevPros are the same, we do not need to change our state, just return.
    if (prevProps === this.props) return;
    this.updateStateIfNeed();
  }

  updateStateIfNeed() {
    this.setState({ buttonClicked: false });
  }

  handleClick = () => {
    this.setState({ buttonClicked: true });
  }

  render() {
    const { content } = this.props;
    const { buttonClicked } = this.state;
    if (buttonClicked) {
      return (
        <span>{content}</span>
      );
    }
    return (
      <Button variant="outline-dark" onClick={this.handleClick}>Show</Button>
    );
  }
}

export default HideAndShow;
