// @flow
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import pick from 'lodash/pick';
import valuesIn from 'lodash/valuesIn';
import without from 'lodash/without';

export type cloudItem = {
  text: string,
  value: number,
};

export type specificItemWordPart = {
  id: string,
  label: string,
  value: any,
};

export type wordObject = {
  enPlain: Array<string>,
  esPlain: Array<string>,
  svDefsPlain: Array<string>,
  svSentencesPlain: Array<string>,
  svRelatedPlain: Array<string>,
  svSynPlain: Array<string>,
  enRender: Array<cloudItem>,
  esRender: Array<cloudItem>,
  svDefsRender: Array<cloudItem>,
  specific: Array<specificItemWordPart>,
  popStats: number,
  svParts: Array<string>,
  sv: string,
  wordType: Array<string>,
  isAdjective: boolean,
  isAdverb: boolean,
  isConjunction: boolean,
  isNoun: boolean,
  isVerb: boolean,
};

// This is the rawWord we get from lib/queries
export interface rawWordInterface {
  id: string,

  wordType: string,

  isNoun: boolean,
  isAdverb: boolean,
  isVerb: boolean,
  isAdjective: boolean,
  isConjunction: boolean,

  sv: string,

  en1: string,
  en2: string,
  en3: string,
  es1: string,
  es2: string,

  svSentencesExample: string,
  svWordRelated: string,

  svDef1: string,
  svDef2: string,
  svDef3: string,
  svDef4: string,
  svDef5: string,
  svSyn: string,
  svMp3: string,
  svParts: string,

  popStats: number,
  svNounEndings: string,
  svNounEndingsSvsv: string,
  svNounEn: boolean,
  svNounEtt: boolean,
  svNounEttn: string,
  svNounSingIndef: string,
  svNounSingDefin: string,
  svNounPluIndef: string,
  svNounPluDefin: string,
  svVerbEndings: string,
  svVerbEndingsSvsv: string,
  svVerbImperativ: string,
  svVerbSupinum: string,
  svVerbPresent: string,
  svVerbPreteritum: string,
  svVerbInfinitiv: string,
  svVerbPresentParticip: string,
  svVerbGroup: string,
  svAdjEndings: string,
  svAdjEndingsSvsv: string,
  svAdjIndefSingEn: string,
  svAdjIndefSingEtt: string,
  svAdjDefinSing: string,
  svAdjPlural: string,
  svAdjComparativ: string,
  svAdjSuperlativObest: string,
  svAdjSuperlativBest: string,
}

// There is no sentenceObject because the parsed "sentence" is the same than the raw
export interface rawSentenceInterface {
  id: string,
  en: string,
  es: string,
  sv: string,
}

export interface renderObject {
  label: string, // what we show in dropdown
  value: string, // what we execute
}

// -- OLD --

export type CloudWordType = {
  text: string,
  value: number,
}

//
export type wordParsed = {
  definitions: Object,
  enPlain: Array<*>,
  english: Object,
  exampleSentences: Object,
  relatedWords: Object,
  wordType: Object,
  sv: Object,
  specific: Array<*>,
}

// We export the function, so we can test it later
export function getCleanWordList(djangoStringListArray: string) {
  if (!djangoStringListArray) return [];
  // Some words can have ' (&#39;), like: ['he&#39;s your son', 'b']
  // In this case we replace for another quote: ['he´s your son', 'b']
  // Then we convert all to lower case
  const stringList = djangoStringListArray.replace('&#39;', '´').toLowerCase();

  // We get: "['procentandel', 'andelshus', 'patata; banana']" (as a string)
  // Step a: remove first and last characters, and quotes
  // Result: procentandel, procentandel
  const a = stringList.substring(1, stringList.length - 1).replace(/'/g, '');
  // Step b: convert it into an array
  // Result: Array [ "procentandel", " andelshus", "patata; banana"]
  const b = a.split(',');
  // Step c: We have to break "patata; banana" in two different ones
  const c = b;
  // const c = map(b, (x) => {
  //   if (x.includes(';')) return x.split(';');
  //   return x;
  // });
  // Step d: flat the array again! We have [ "procentandel", " andelshus", ["patata", "banana"]]
  const d = flatten(c);
  // Result: Array [ "procentandel", "andelshus" ]
  // Replace some weird characters
  const rep = map(d, (x) => x.trim().replace(/`/g, '"').replace(/¸/g, ','));
  return uniq(rep);
}

export function extractLists(props: rawWordInterface, arrayKeys: Array<string>) {
  // Get keys in props, discarding the other ones, so from props we extract the values
  // taking the keys in arrayKeys. For example: { enFolk: "['a', 'b']", enGlosbe: "['e', 'f']" }
  // And from there, we take the values from the object: [ "['a', 'b']", "['e', 'f']" ]
  const values = valuesIn(pick(props, arrayKeys));
  // We have [ "['a', 'b']", "['e', 'f']" ]
  const parsedList = values.map((x) => getCleanWordList(x));
  // We may have some empty strings that we have to remove (if, for example, enFolk is empty)
  // but first we have to flat the array
  const result = without(flatten(parsedList), null, '');
  // We do not return unique values, so we can group then in renderPlainAsCloud()
  return result;
}

// A word can have two different type content!! Can be NOUN and VERB, for example
export function getWordTypeContent(props: rawWordInterface) {
  const {
    wordType,
    // Noun
    svNounEndings,
    svNounEndingsSvsv,
    svNounEn,
    svNounEtt,
    svNounEttn,
    svNounSingIndef,
    svNounSingDefin,
    svNounPluIndef,
    svNounPluDefin,
    // Verb
    svVerbEndings,
    svVerbEndingsSvsv,
    svVerbImperativ,
    svVerbSupinum,
    svVerbPresent,
    svVerbPreteritum,
    svVerbInfinitiv,
    svVerbPresentParticip,
    // Adj
    svAdjEndings,
    svAdjEndingsSvsv,
    svAdjIndefSingEn,
    svAdjIndefSingEtt,
    svAdjDefinSing,
    svAdjPlural,
    svAdjComparativ,
    svAdjSuperlativObest,
    svAdjSuperlativBest,
  } = props;
  let result = [];
  if (wordType.includes('NOUN')) {
    const nounContent = [
      { id: 'sv_noun_endings_long', label: 'Noun endings long', value: getCleanWordList(svNounEndings) },
      { id: 'sv_noun_endings_short', label: 'Noun endings short', value: getCleanWordList(svNounEndingsSvsv) },
      { id: 'sv_noun_is_en', label: 'Noun is En', value: svNounEn === 1 },
      { id: 'sv_noun_is_ett', label: 'Noun is Ett', value: svNounEtt === 1 },
      { id: 'sv_noun_ettn', label: 'Noun is EttN', value: svNounEttn },
      { id: 'sv_noun_sing_indef', label: 'Sing. ind.', value: svNounSingIndef },
      { id: 'sv_noun_sing_defin', label: 'Sing. def.', value: svNounSingDefin },
      { id: 'sv_noun_plu_indef', label: 'Plu. ind.', value: svNounPluIndef },
      { id: 'sv_noun_plu_defin', label: 'Plu. def.', value: svNounPluDefin },
    ];
    result = [...result, ...nounContent];
  }
  if (wordType.includes('VERB')) {
    const verbContent = [
      { id: 'sv_verb_endings', label: 'Verb endings long', value: getCleanWordList(svVerbEndings) },
      { id: 'sv_verb_endings_svsv', label: 'Verb endings short', value: getCleanWordList(svVerbEndingsSvsv) },
      { id: 'sv_verb_imperativ', label: 'Imperativ', value: svVerbImperativ },
      { id: 'sv_verb_supinum', label: 'Supinum', value: svVerbSupinum },
      { id: 'sv_verb_present', label: 'Present', value: svVerbPresent },
      { id: 'sv_verb_preteritum', label: 'Preteritum', value: svVerbPreteritum },
      { id: 'sv_verb_infinitiv', label: 'Infinitiv', value: svVerbInfinitiv },
      { id: 'sv_verb_present_part', label: 'Pres. Part.', value: svVerbPresentParticip },
    ];
    result = [...result, ...verbContent];
  }
  if (wordType.includes('ADJ')) {
    const adjContent = [
      { id: 'sv_adj_endings', label: 'Adj endings long', value: getCleanWordList(svAdjEndings) },
      { id: 'sv_adj_endings_svsv', label: 'Adj endings short', value: getCleanWordList(svAdjEndingsSvsv) },
      { id: 'sv_adj_indef_sing_en', label: 'Sing. ind. (en)', value: svAdjIndefSingEn },
      { id: 'sv_adj_indef_sing_ett', label: 'Sing. ind. (ett)', value: svAdjIndefSingEtt },
      { id: 'sv_adj_defin_sing', label: 'Sing. def.', value: svAdjDefinSing },
      { id: 'sv_adj_plural', label: 'Plural', value: svAdjPlural },
      { id: 'sv_adj_comparativ', label: 'Comparativ', value: svAdjComparativ },
      { id: 'sv_adj_superlativ_obest', label: 'Superlativ def.', value: svAdjSuperlativObest },
      { id: 'sv_adj_superlativ_best', label: 'Superlativ ind.', value: svAdjSuperlativBest },
    ];
    result = [...result, ...adjContent];
  }
  return result;
}

export function stringToArray(input:string): Array<string> {
  if (!input) return [];
  return JSON.parse(input.replace(/"/g, '').replace(/'/g, '"').replace(/¸/g, ','));
}
