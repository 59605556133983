// @flow
import React, { Component } from 'react';

import {
  ListGroup,
} from 'react-bootstrap';

import { renderCloudAsSingleLine } from './FieldRenders';
import type { cloudItem } from '../PropsInterface';

// import reverse from 'lodash/reverse';

interface State {}

interface Props {
  right: Array<Array<cloudItem>>,
  left: Array<Array<cloudItem>>,
}

class ShowAllTableRightLeft extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { right, left } = this.props;

    // Reverse array (first ones are last in original)
    // reverse(right);
    // reverse(left);

    /* eslint react/no-array-index-key: 0 */
    const items = right.map((element, index) => (
      <ListGroup.Item key={index}>
        <div className="text-left">
          {renderCloudAsSingleLine(left[index])}
        </div>
        <div className="text-right font-italic">
          {renderCloudAsSingleLine(right[index])}
        </div>
      </ListGroup.Item>
    ));

    return (
      <ListGroup variant="flush">
        {items}
      </ListGroup>
    );
  }
}

export default ShowAllTableRightLeft;
