// @flow
import React, { Component } from 'react';

import {
  Popover,
  OverlayTrigger,
  Button,
} from 'react-bootstrap';

interface Props {
  msg: string,
  place: string,
}
interface State {}

class CustomPopover extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { msg, place } = this.props;
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          {msg}
        </Popover.Content>
      </Popover>
    );
    return (
      <OverlayTrigger trigger="click" placement={place} overlay={popover}>
        <Button variant="info" size="sm">?</Button>
      </OverlayTrigger>
    );
  }
}

export default CustomPopover;
