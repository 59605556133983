// @flow
import React, { Component } from 'react';

import {
  Row,
  Col,
} from 'react-bootstrap';

interface State {}

interface Props {
  title: string,
  sub?: string,
  body?: string,
}

class PageTitle extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      title,
      sub,
      body,
    } = this.props;

    return (
      <Row>
        <Col>
          <h1 className="h1-responsive text-center text-capitalize">{title}</h1>
          <h4>{sub}</h4>
          <p>{body}</p>
        </Col>
      </Row>
    );
  }
}

export default PageTitle;
